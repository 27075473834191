import React, { useRef, useEffect } from 'react';
import * as typeformEmbed from '@typeform/embed';

const ContactForm = () => {
  const typeformRef = useRef(null);

  useEffect(() => {
    typeformEmbed.makeWidget(
      typeformRef.current,
      'https://hellougo.typeform.com/info-request-cc',
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 50,
      }
    );
  }, [typeformRef]);

  return <div ref={typeformRef} style={{ height: '70vh' }}></div>;
};
export default ContactForm;
