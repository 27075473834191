import React from 'react';
import SEO from '../core/meta/SEO';
import { PageProps } from 'gatsby';
import { Container } from '../core/layout/Layout';
import Heading from '../core/typography/Heading';
import BackgroundImage from 'gatsby-background-image';
import { CitiesQuery } from '../../../generated/graphql-types';
import 'twin.macro';
import { renderOptions } from '../../contentful/render-options';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import Slider from '../views/Slider/Slider';
import Image from 'gatsby-image';
import ContactForm from '../partials/ContactForm/ContactForm';
import PartnersList from '../partials/PartnersList/PartnersList';

const swiperOptions: SwiperOptions = {
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 32,
    },
  },
};

const CityPage = ({
  pageContext: { pageData },
}: PageProps<
  unknown,
  { pageData: CitiesQuery['cities']['nodes'][number] }
>) => {
  // @ts-ignore
  return (
    <>
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        metaImage={pageData.mainImage}
        hidePageSuffix
        url={`/${pageData.slug}`}
      />
      <BackgroundImage fluid={pageData.mainImage.fluid}>
        <div tw="bg-black bg-opacity-60 py-16">
          <Container>
            <Heading variant="h1" tw="text-center mb-0 text-white">
              {pageData.name}
            </Heading>
          </Container>
        </div>
      </BackgroundImage>
      <section tw="space-y-16 py-16">
        <div>
          <Container>
            {pageData.content1 &&
              //@ts-ignore
              renderRichText(pageData.content1, renderOptions)}
          </Container>
        </div>
        <div>
          <Container>
            <div tw="flex flex-wrap space-y-10 md:space-y-0 -mx-5">
              <div tw="w-full md:w-1/3 px-5">
                {pageData.content2 &&
                  // @ts-ignore
                  renderRichText(pageData.content2, renderOptions)}
              </div>
              <div tw="w-full md:w-1/3 px-5">
                {pageData.content3 &&
                  // @ts-ignore
                  renderRichText(pageData.content3, renderOptions)}
              </div>
              <div tw="w-full md:w-1/3 px-5">
                {pageData.content4 &&
                  // @ts-ignore
                  renderRichText(pageData.content4, renderOptions)}
              </div>
            </div>
          </Container>
        </div>
        {pageData.gallery1 ? (
          <div tw="pb-10">
            <Container>
              <Slider darkControls navOutside hidePagination {...swiperOptions}>
                {pageData.gallery1 &&
                  pageData.gallery1.map((f, i) => (
                    <SwiperSlide key={i}>
                      <Image alt={f.title} fluid={f.fluid} tw="rounded-md" />
                    </SwiperSlide>
                  ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <span></span>
        )}
        <div>
          <Container>
            <div tw="flex flex-wrap space-y-10 md:space-y-0">
              <div tw="w-full md:w-1/2">
                {/* @ts-ignore */}
                {pageData.content5 &&
                  //@ts-ignore
                  renderRichText(pageData.content5, renderOptions)}
              </div>
              <div tw="w-full md:w-1/2">
                {pageData.content6 &&
                  //@ts-ignore
                  renderRichText(pageData.content6, renderOptions)}
              </div>
            </div>

            <PartnersList />
          </Container>
        </div>
        {pageData.content7 && (
          <div tw="bg-primary-300 py-16">
            <Container>
              <div tw="flex flex-wrap rounded-lg bg-white p-10 space-y-10 md:space-y-0">
                <div tw="w-full md:w-3/4">
                  {/* @ts-ignore */}
                  {renderRichText(pageData.content7, renderOptions)}
                </div>
                <div tw="w-full md:w-1/4 md:pt-16">
                  <div tw="flex flex-wrap flex-col space-y-6">
                    {pageData.gallery2 &&
                      pageData.gallery2.map((f, i) => (
                        <div key={i}>
                          <BackgroundImage
                            style={{ height: '150px' }}
                            fluid={f.fluid}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        )}
        <div>
          <Container>
            <Heading variant="h3">Contattaci</Heading>
            <ContactForm />
          </Container>
        </div>
      </section>
    </>
  );
};
export default CityPage;
